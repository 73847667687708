<template>
    <div>
        <div class="three" @mouseover="() => {canAnimate = true; animate();}" @mouseleave="() => {canAnimate = false;}" :id="'fbx_preview_' + id"><div><icon type="video"/></div></div>
    </div>
</template>

<script>
    import Icon from "../Icon";
    export default {
        name: "Three",
        components: {
            Icon
        },
        props: {
            previewUri: {type: String, default: ''},
            previewData: { default: null },
            id: {type: String, required: true},
            limitedPreview: {type: Boolean, default: false},
        },
        data() {
            return {
                resizeObserver: {},
                canAnimate: false,
                camera: null,
                scene: null,
                renderer: null,
                mesh: null,
                loader: null,
                fbx: null,
                boundingBox: {
                    max: {
                        x: 0,
                        y: 0,
                        z: 0,
                    },
                    min: {
                        x: 0,
                        y: 0,
                        z: 0,
                    },
                },
                cleanObject: {
                    children: []
                },
            }
        },
        watch: {
            previewUri: function() {
                this.$forceUpdate();
                this.init();
            },
            previewData: function() {
                this.$forceUpdate();
                this.init();
            }
        },
        mounted() {
            if(this.previewData) {
                this.$forceUpdate();
                this.init();
            }
            let $this = this;
            this.container = document.getElementById('fbx_preview_' + this.id);
            //if window get resized
            window.addEventListener('resize', ($this.canvasResize), false);
            this.resizeObserver = new ResizeObserver(entries => {
              // eslint-disable-next-line no-unused-vars
              for (let entry of entries) {
                $this.canvasResize()
              }
            });
            this.resizeObserver.observe(this.container);
        },
        destroyed() {
          this.resizeObserver.unobserve(this.container);
        },
        methods: {
            load: function(url){
                let $this = this;
                let Three = this.Three;
                this.loader.load(url, function (object) {
                    let container = document.getElementById('fbx_preview_' + $this.id);
                    $this.renderer = new Three.WebGLRenderer({antialias: true,  alpha: true });
                    $this.renderer.setSize(container.clientWidth, container.clientHeight);

                    container.innerHTML = '';
                    container.appendChild($this.renderer.domElement);

                    $this.camera = new Three.PerspectiveCamera(80, container.clientWidth / container.clientHeight, 0.01, 100000);
                    $this.camera.position.z = 1;
                    $this.scene.add($this.camera);
                    const controls = new Three.OrbitControls($this.camera, $this.renderer.domElement);

                    object.name = "fbx";
                    object.position.set(0,0,0);

                    $this.materialRemoveReflectivity(object);

                    $this.scene.add(object);
                    $this.sfxBoundingBox(object);

                    //$this.scene.add(new Three.AxesHelper(20));

                    // White directional light at Full intensity.
                    let directionalLight = new Three.DirectionalLight(0xffffff, 1);
                    directionalLight.position.set(20,20,0);
                    directionalLight.name = "Directional Light";
                    directionalLight.castShadow = false;
                    directionalLight.target = object;
                    $this.camera.add(directionalLight);

                    // set camera according to bounding Box
                    $this.fitCameraToSelection($this.camera, controls, [object]);

                    $this.animate();
                }, undefined, function (e) {
                    console.error(e);
                });
            },
            init: function(){
                cancelAnimationFrame(document.getElementById('fbx_preview_' + this.id));
                let Three = this.Three;

                this.scene = new Three.Scene();

                var ambientLight = new Three.AmbientLight(0x222222);
                this.scene.add(ambientLight);

                this.loader = new Three.FBXLoader();

                if(this.previewData) {
                    let url = this.previewData;
                    if(typeof(this.previewData) != 'string') {
                      url = URL.createObjectURL(this.previewData.text);
                    }
                    this.load(url);
                }
                else if(this.previewUri) {
                    let arr = this.previewUri.split('/');
                    let id = arr[arr.length - 2];
                    let key = arr[arr.length - 1];

                    this.$store.dispatch('clientDownloadAsset', {id: id, key: key}).then(data => {
                        let url = URL.createObjectURL(data.text);
                        this.load(url);
                    });
                }
            },
            sfxBoundingBox: function (object) {
                object.updateWorldMatrix = function(){};
                for(let i = 0; i < object.children.length; i++) {
                    this.sfxBoundingBox(object.children[i]);
                }
            },
            animate: function () {
                if((!this.limitedPreview || this.canAnimate) && this.renderer) {
                  requestAnimationFrame(this.animate);
                }
                if(this.renderer) {
                  try {
                    this.renderer.render(this.scene, this.camera);
                  } catch {
                    this.canAnimate = false;
                  }
                }
            },
            fitCameraToSelection: function (camera, controls, selection, fitOffset = 1.2) {
                let Three = this.Three;
                const box = new Three.Box3();

                for (const object of selection) box.expandByObject(object);

                const size = box.getSize(new Three.Vector3());
                const center = box.getCenter(new Three.Vector3());

                this.rotateAboutPoint(selection[0], center, new Three.Vector3(1,0,0), Three.Math.degToRad(-90), false);

                const maxSize = Math.max(size.x, size.y, size.z);
                const fitHeightDistance = maxSize / (2 * Math.atan(Math.PI * camera.fov / 360));
                const fitWidthDistance = fitHeightDistance / camera.aspect;
                const distance = fitOffset * Math.max(fitHeightDistance, fitWidthDistance);

                const direction = controls.target.clone()
                    .sub(camera.position)
                    .normalize()
                    .multiplyScalar(distance);

                controls.maxDistance = distance * 2;
                controls.target.copy(center);

                camera.near = distance / 100;
                camera.far = distance * 100;
                camera.updateProjectionMatrix();

                camera.position.copy(controls.target).sub(direction);
                camera.position.copy(selection[0].position).sub(direction);

                this.rotateAboutPoint(camera, new Three.Vector3(0,0,0), new Three.Vector3(-1,1,0), Three.Math.degToRad(45), false);
                controls.update();
            },
            rotateAboutPoint: function(obj, point, axis, theta, pointIsWorld){
                pointIsWorld = (pointIsWorld === undefined)? false : pointIsWorld;

                if(pointIsWorld){
                    obj.parent.localToWorld(obj.position); // compensate for world coordinate
                }

                obj.position.sub(point); // remove the offset
                obj.position.applyAxisAngle(axis, theta); // rotate the POSITION
                obj.position.add(point); // re-add the offset

                if(pointIsWorld){
                    obj.parent.worldToLocal(obj.position); // undo world coordinates compensation
                }

                obj.rotateOnAxis(axis, theta); // rotate the OBJECT
            },
            canvasResize: function() {
              if(this.camera) {
                this.camera.aspect = this.container.clientWidth / this.container.clientHeight;
                this.camera.updateProjectionMatrix();
              }
              if(this.renderer) {
                this.renderer.setSize(this.container.clientWidth, this.container.clientHeight);
              }
            },
            materialRemoveReflectivity: function (object) {
              object.traverse(function(Group) {
                Group.children.forEach( mesh => {
                  let mat = mesh.material;
                  if (Array.isArray(mat)) {
                    mat.forEach( submat => {
                      if (submat && submat.isMeshPhongMaterial){
                        submat.reflectivity = 0.0;
                      }
                    });
                  }else {
                    if (mat && mat.isMeshPhongMaterial){
                      mat.reflectivity = 0.0;
                    }
                  }
                  mesh.children.forEach( child =>{
                    let mat = child.material;
                    if (Array.isArray(mat)) {
                      mat.forEach( submat => {
                        if (submat && submat.isMeshPhongMaterial){
                          submat.reflectivity = 0.0;
                        }
                      });
                    }else {
                      if (mat && mat.isMeshPhongMaterial){
                        mat.reflectivity = 0.0;
                      }
                    }
                  });
                });
              });
            },

        },
    };
</script>

<style lang="scss">
    .three {
        width: 100%;
        padding-top:100%;
        position:relative;
        background: rgb(221,221,221);
        background: linear-gradient(0deg, rgba(221,221,221,1) 0%, rgba(177,174,166,1) 100%);
        canvas {
            position:absolute;
            top:0;
            left:0;
            height:100%;
            width:100%;
        }
        .icon {
            position:absolute;
            top:50%;
            left:50%;
            -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
            font-size:4.5em;
        }
    }
    .thumbnail.preview #threeContainer {
        position:absolute;
    }
</style>
